import { LOCALE } from '$lib/constants/locale';
import { type CookieConsentConfig } from 'vanilla-cookieconsent';
import * as pkg from 'vanilla-cookieconsent';
const { getUserPreferences } = pkg;

const generateTranslations = (locales: typeof LOCALE, content: Record<string, string>) => {
	const translations: CookieConsentConfig['language']['translations'] = {};
	const data: CookieConsentConfig['language']['translations'][string] = {
		consentModal: {
			title: content['consentModal-title'],
			description: content['consentModal-description'],
			acceptAllBtn: content['consentModal-acceptAllBtn'],
			acceptNecessaryBtn: content['consentModal-acceptNecessaryBtn'],
			showPreferencesBtn: content['consentModal-showPreferencesBtn'],
			footer: content['consentModal-footer']
		},
		preferencesModal: {
			title: content['preferencesModal-title'],
			acceptAllBtn: content['preferencesModal-acceptAllBtn'],
			acceptNecessaryBtn: content['preferencesModal-acceptNecessaryBtn'],
			savePreferencesBtn: content['preferencesModal-savePreferencesBtn'],
			closeIconLabel: content['preferencesModal-closeIconLabel'],
			serviceCounterLabel: content['preferencesModal-serviceCounterLabel'],
			sections: [
				{
					title: content['preferencesModal-sections-0-title'],
					description: content['preferencesModal-sections-0-description']
				},
				{
					title: content['preferencesModal-sections-1-title'],
					description: content['preferencesModal-sections-1-description'],
					linkedCategory: 'necessary'
				},
				{
					title: content['preferencesModal-sections-2-title'],
					description: content['preferencesModal-sections-2-description'],
					linkedCategory: 'analytics',
					cookieTable: {
						caption: content['preferencesModal-sections-2-caption'],
						headers: {
							name: content['preferencesModal-sections-2-headers-0'],
							domain: content['preferencesModal-sections-2-headers-1'],
							desc: content['preferencesModal-sections-2-headers-2']
						},
						body: [
							{
								name: content['preferencesModal-sections-2-cookie-0-name'],
								domain: content['preferencesModal-sections-2-cookie-0-domain'],
								desc: content['preferencesModal-sections-2-cookie-0-description']
							},
							{
								name: content['preferencesModal-sections-2-cookie-1-name'],
								domain: content['preferencesModal-sections-2-cookie-1-domain'],
								desc: content['preferencesModal-sections-2-cookie-1-description']
							}
						]
					}
				},
				{
					title: content['preferencesModal-sections-3-title'],
					description: content['preferencesModal-sections-3-description'],
					linkedCategory: 'ads'
				},
				{
					title: content['preferencesModal-sections-4-title'],
					description: content['preferencesModal-sections-4-description']
				}
			]
		}
	};

	locales.forEach((locale) => {
		translations[locale] = data;
	});
	return translations;
};

const getConfig: (content: Record<string, string>) => CookieConsentConfig = (content) => {
	return {
		guiOptions: {
			consentModal: {
				layout: 'bar inline',
				position: 'bottom left',
				equalWeightButtons: false,
				flipButtons: true
			},
			preferencesModal: {
				layout: 'box',
				position: 'right',
				equalWeightButtons: true,
				flipButtons: false
			}
		},
		disablePageInteraction: false,
		categories: {
			necessary: {
				enabled: true,
				readOnly: true
			},
			analytics: {
				enabled: true,
				autoClear: {
					cookies: [
						{
							name: /^_ga/
						},
						{
							name: '_gid'
						}
					]
				},

				services: {
					ga: {
						label: 'Google Analytics',
						onAccept: () => {
							const datalayer = (window as any).dataLayer || [];
							datalayer.push({
								event: 'consentGiven',
								cookieName: '_ga',
								cookieValue: 'true'
							});
						},
						onReject: () => {
							const datalayer = (window as any).dataLayer || [];
							datalayer.push({
								event: 'consentWithdrawn',
								cookieName: '_ga',
								cookieValue: 'true'
							});
						}
					},
					another: {
						label: 'Sentry (apm)',
						onAccept: undefined,
						onReject: undefined
					}
				}
			},
			ads: {
				enabled: true,
				autoClear: {
					cookies: [
						{
							name: /^_ga/
						},
						{
							name: '_gid'
						}
					]
				}
			}
		},

		onConsent: ({ cookie }) => {
			const preferences = {
				ad_storage: cookie.categories.includes('ads') ? 'granted' : 'denied',
				ad_user_data: cookie.categories.includes('ads') ? 'granted' : 'denied',
				ad_personalization: cookie.categories.includes('ads') ? 'granted' : 'denied',
				analytics_storage: cookie.categories.includes('analytics') ? 'granted' : 'denied'
			};
			(window as any).dataLayer = (window as any).dataLayer || [];
			(window as any).gtag('consent', 'update', preferences);
		},

		language: {
			default: 'en',
			translations: generateTranslations(LOCALE, content)
		}
	};
};

export default getConfig;
